import {Actor, CollisionEndEvent, CollisionStartEvent, CollisionType, Color} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {StoreKeeperBox} from "./storeKeeperPazzleBox";
import {StoreKeeperPazzlePlayer} from "./storeKeeperPazzlePlayer";

export interface StoreKeeperEndPointPazzleProperties {
};

export class StoreKeeperPazzleEndPoint extends withFieldEntity(Actor) {
  private readonly properties: StoreKeeperEndPointPazzleProperties;
  private pazzlePlayer?: StoreKeeperPazzlePlayer;
  public storeKeeperBox: StoreKeeperBox | null;

  public constructor(configs: FieldEntityConfigs<StoreKeeperEndPointPazzleProperties>) {
    super({
      ...configs,
      color: Color.Yellow
    });
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.storeKeeperBox = null;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.on("collisionend", this.onCollisionEnd.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof StoreKeeperBox) {
      this.storeKeeperBox = event.other;
      // クリア確認
      this.pazzlePlayer?.checkComplete();
    } else if (event.other instanceof StoreKeeperPazzlePlayer) {
      this.pazzlePlayer = event.other as StoreKeeperPazzlePlayer;
    }
  }

  public onCollisionEnd(event: CollisionEndEvent): void {
    if (event.other instanceof StoreKeeperBox) {
      this.storeKeeperBox = null;
    }
  }
}
