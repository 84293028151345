import {Actor, CollisionEndEvent, CollisionStartEvent, CollisionType} from "excalibur";
import {StoreKeeperBox} from "./storeKeeperPazzleBox";

export class StoreKeeperPazzleBoxTrigger extends Actor {
  public storeKeeperPazzleBox?: StoreKeeperBox;

  public constructor() {
    super({width: 34, height: 34});
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.on("collisionend", this.onCollisionEnd.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    const player = this.storeKeeperPazzleBox?.player;
    if (event.other === player) {
        this.storeKeeperPazzleBox!.changeCollisionType(CollisionType.Active);
    }
  }

  public onCollisionEnd(event: CollisionEndEvent): void {
    const player = this.storeKeeperPazzleBox?.player;
    if (event.other === player) {
        this.storeKeeperPazzleBox!.changeCollisionType(CollisionType.Fixed);
    }
  }
}