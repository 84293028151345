import {Actor, CollisionType, Color, Engine, Font, Sprite, Text, vec} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {PazzlePlayer} from "./pazzlePlayer";

export interface PazzleObjectProperties {
    moji: string;
  };

export class PazzleObject extends withFieldEntity(Actor) {
  private readonly properties: PazzleObjectProperties;
  public sprite!: Sprite;
  private pazzleController?: PazzlePlayer;
  public moji: string;

  public constructor(configs: FieldEntityConfigs<PazzleObjectProperties>) {
    super({
      ...configs,
      color: Color.White
      // width: 96,
      // height: 96
    });
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    // this.on("collisionstart", this.onCollisionStart.bind(this));

    const actor: Actor = new Actor({color: Color.White});
    actor.pos = vec(0, 48);
    this.addChild(actor);

    const text = new Text({
      text: this.properties.moji,
      font: new Font({
        bold: true,
        color: Color.Black,
        size: 56
      })
    });
    actor.graphics.add(text);

    this.moji = this.properties.moji;

  }

  public override onPreUpdate(_engine: Engine, _delta: number): void {
    if (this.pazzleController === null) {
      this.pazzleController = this.field.getFirstEntity(PazzlePlayer);
    }
  }
}