import {Actor, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {SlidePazzleImageView} from "./slidePazzleImageView";
import {SlidePazzlePlayer} from "./slidePazzlePlayer";

export interface SlidePazzleTriggerProperties {
};

export class SlidePazzleTrigger extends withFieldEntity(Actor) {
  private readonly properties: SlidePazzleTriggerProperties;
  private pazzlePlayer?: SlidePazzlePlayer;
  public connectTriggerSet: Set<SlidePazzleTrigger>;
  public imageView: SlidePazzleImageView | null;
  public index: number;

  public constructor(configs: FieldEntityConfigs<SlidePazzleTriggerProperties>) {
    super({...configs,
      width: 24,
      height: 24});
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.connectTriggerSet = new Set<SlidePazzleTrigger>();
    this.index = -1;// this.properties.index;
    this.imageView = null;
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other === this.player) {
      this.slide();
    } else if (event.other instanceof SlidePazzlePlayer) {
      this.pazzlePlayer = event.other;
    }
  }

  public slide(): void {
    let tergetTrigger = null;
    this.connectTriggerSet.forEach((x) => {
      if (x.imageView === null) {
        tergetTrigger = x;

      }
    });

    if (tergetTrigger !== null) {
      this.pazzlePlayer?.changeImage(this, tergetTrigger);
    }
  }

  public setImage(imageView: SlidePazzleImageView | null): void {
    if (this.imageView !== null) {
      this.removeChild(this.imageView);
    }

    this.imageView = imageView;
    if (imageView !== null) {
      this.addChild(imageView);
    }
  }

  public removeImage(): SlidePazzleImageView | null {
    if (this.imageView !== null) {
      this.removeChild(this.imageView);
    }
    return this.imageView;
  }

}


