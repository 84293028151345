import {Actor, CollisionType, ImageSource, Vector, vec} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {StoreKeeperPazzleBoxTrigger} from "./storeKeeperPazzleBoxTrigger";

export interface StorekeeperBoxProperties {
};

export class StoreKeeperBox extends withFieldEntity(Actor) {
  private readonly properties: StorekeeperBoxProperties;
  private startPos: Vector;

  public constructor(configs: FieldEntityConfigs<StorekeeperBoxProperties>) {
    super({...configs});
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Fixed;
    this.startPos = vec(this.pos.x, this.pos.y);

    // pazzleImage_cat.png
    const image = new ImageSource("assets/sprites/common/storekeeperbox.png");
    image.load().then(() => {
      const sprite = image.toSprite();
      sprite.width = this.width;
      sprite.height = this.height;
      this.graphics.add("box", sprite);
      this.graphics.show("box");
    });

    const trigger = new StoreKeeperPazzleBoxTrigger();
    trigger.storeKeeperPazzleBox = this;
    this.addChild(trigger);
  }

  public changeCollisionType(collisionType: CollisionType): void {
    this.body.collisionType = collisionType;
  }

  public resetPosition(): void {
    this.pos = this.startPos;
  }
}


