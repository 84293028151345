import {Actor, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {PazzleResultView} from "./pazzleResultView";
import {StoreKeeperBox} from "./storeKeeperPazzleBox";
import {StoreKeeperPazzleEndPoint} from "./storeKeeperPazzleEndPoint";


export interface StoreKeeperPazzlePlayerProperties {
};

export class StoreKeeperPazzlePlayer extends withFieldEntity(Actor) {
  private readonly properties: StoreKeeperPazzlePlayerProperties;
  private storeKeeperBoxSet: Set<StoreKeeperBox>;
  private storeKeeperEndPointSet: Set<StoreKeeperPazzleEndPoint>;
  private pazzleResultView?: PazzleResultView;

  public constructor(configs: FieldEntityConfigs<StoreKeeperPazzlePlayerProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.storeKeeperBoxSet = new Set<StoreKeeperBox>();
    this.storeKeeperEndPointSet = new Set<StoreKeeperPazzleEndPoint>();
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof StoreKeeperBox) {
      this.storeKeeperBoxSet.add(event.other);
    } else if (event.other instanceof StoreKeeperPazzleEndPoint) {
      this.storeKeeperEndPointSet.add(event.other);
    } else if (event.other instanceof PazzleResultView) {
      this.pazzleResultView = event.other;
    }
  }

  public checkComplete(): void {
    let isComplite = true;
    this.storeKeeperEndPointSet?.forEach((x) => {
      if (x.storeKeeperBox === null || x.storeKeeperBox === undefined) {
        isComplite = false;
      }
    });

    if (isComplite) {
      this.pazzleResultView?.Show();
    }
  }

  public resetPosition(): void {
    this.storeKeeperBoxSet?.forEach((x) => {
      x.resetPosition();
    });
  }
}