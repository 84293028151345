import {writeLog} from "@baton8/qroud-lib-repositories";
import {Actor, CollisionStartEvent, CollisionType, Color, ImageSource, SpriteSheet} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {PazzleResultView} from "./pazzleResultView";
import {SlidePazzleImageView} from "./slidePazzleImageView";
import {SlidePazzleTrigger} from "./slidePazzleTrigger";

export interface SlidePazzlePlayerProperties {
    imageUrl: string;
    columns: number;
    rows: number;
};

export class SlidePazzlePlayer extends withFieldEntity(Actor) {
  private readonly properties: SlidePazzlePlayerProperties;
  private pazzleResultView?: PazzleResultView;
  private slidePazzleTriggerSet: Set<SlidePazzleTrigger>;
  private spriteSheet?: SpriteSheet;
  private isComplite: boolean;
  private tileMax: number;

  public constructor(configs: FieldEntityConfigs<SlidePazzlePlayerProperties>) {
    super({...configs, color: new Color(255, 255, 0, 0.5)});
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.slidePazzleTriggerSet = new Set<SlidePazzleTrigger>();
    this.isComplite = false;
    this.tileMax = this.properties.columns * this.properties.rows;
    this.createImage();

    writeLog("SlidePazzlePlayer", "constructor!!");
  }

  public createImage(): void {
    const imageSource = new ImageSource(this.properties.imageUrl);
    imageSource.load().then(() => {
      const spriteWidth = imageSource.width / this.properties.columns;
      const spriteHeight = imageSource.height / this.properties.rows;
      this.spriteSheet = SpriteSheet.fromImageSource({
        image: imageSource,
        grid: {columns: this.properties.columns, rows: this.properties.rows, spriteWidth, spriteHeight}
      });
      this.setImage();
    });
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof SlidePazzleTrigger) {
      this.slidePazzleTriggerSet.add(event.other);
      if (this.slidePazzleTriggerSet.size === this.tileMax) {
        this.setIndex();
        this.slidePazzleTriggerSet.forEach((a) => {
          this.slidePazzleTriggerSet.forEach((b) => {
            if (a.index !== b.index) {
              const xx = a.pos.x - b.pos.x;
              const yy = a.pos.y - b.pos.y;
              const spaceLength = 55;
              if (xx * xx + yy * yy <= spaceLength * spaceLength) {
                a.connectTriggerSet.add(b);
              }
            }
          });
        });
      }
      this.setImage();
    } else if (event.other instanceof PazzleResultView) {
      this.pazzleResultView = event.other;
    }
  }

  public setIndex(): void {
    const slideTriggerArray = Array.from(this.slidePazzleTriggerSet).sort((a, b) => a.pos.x - b.pos.x + (a.pos.y - b.pos.y) * 10);
    slideTriggerArray.forEach((value, i) => {
      value.index = i;
    });
  }

  public setImage(): void {
    if (this.slidePazzleTriggerSet.size === this.tileMax && this.spriteSheet !== undefined) {
      let count = 0;
      const slideTriggerArray = Array.from(this.slidePazzleTriggerSet).sort((a, b) => a.index - b.index);// [...this.slidePazzleTriggerSet];
      for (let y = 0; y < this.properties.rows; y ++) {
        for (let x = 0; x < this.properties.columns; x ++) {
          if (count !== 0) {
            const imageView = new SlidePazzleImageView();
            imageView.setImage(count, this.spriteSheet.getSprite(x, y));
            slideTriggerArray[count].setImage(imageView);
          }
          count ++;
        }
      }

      this.slidePazzleTriggerSet = new Set(slideTriggerArray);
      this.sortRandom();
    }
  }

  public sortRandom(): void {
    const testSet = new Set<number>();
    for (let x = 1; x < this.tileMax; x ++) {
      testSet.add(x);
    }

    const testArray = Array.from(testSet).sort((a, b) => 0.5 - Math.random());
    const slideTriggerArray = Array.from(this.slidePazzleTriggerSet).sort((a, b) => a.index - b.index);
    testArray.forEach((x) => {
      // 空白のタイル以外をシャッフルする
      if (x !== this.tileMax - 1) {
        this.changeImage(slideTriggerArray[x], slideTriggerArray[x + 1]);
      }
    });

    // this.changeImage(slideTriggerArray[0], slideTriggerArray[1]);
  }

  public checkComplite(): void {
    let isComplite = true;
    const slideTriggerArray = [...this.slidePazzleTriggerSet];
    slideTriggerArray.forEach((value) => {
      if (value.imageView !== null) {
        if (value.index !== value.imageView.index) {
          isComplite = false;
        }
      }
    });

    if (isComplite) {
      this.pazzleResultView?.Show();
      this.isComplite = isComplite;
    }
  }


  public changeImage(a: SlidePazzleTrigger, b: SlidePazzleTrigger): void {
    if (!this.isComplite) {
      const imageView0 = a.removeImage();
      const imageView1 = b.removeImage();
      a.setImage(imageView1);
      b.setImage(imageView0);
      // 完成チェック
      this.checkComplite();
    }
  }
}