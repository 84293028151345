import {APP_IDS, createEncounter, global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faXmarkCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import {
  Actor,
  CollisionType,
  Engine,
  Random,
  Shape,
  Vector,
  vec
} from "excalibur";
import {ReactNode} from "react";
import {alpha, color, filterBlur, size} from "src/components";
import {fontWeight, lineHeight} from "src/components/constants/constants";
import {NpcQuizPane} from "src/components/modules/npcQuizPane";
import {YesNoPane} from "src/components/modules/yesNoPane";
import {FollowingNodeComponent} from "src/ecs";
import {FieldEntityComponent, StoryGenerator, withStories} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {withPlayerTrigger} from "src/ecs/playerTrigger/with";
import {CharacterAnimation} from "src/entities/common/characterAnimation";
import {RandomQuiz, createQuizzes} from "src/entities/common/randomQuizTrigger";
import {createTranslation} from "src/modules/format";
import {SES, sound} from "src/sound";

// TODO: デザイン未調整
const styles = {
  root: css`
    padding-block: ${size(1)};
    padding-inline: ${size(2)};
    border-radius: ${size(2)};
    font-size: ${size(3)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    transform: translate(-50%, -100%);
  `,
  image: css`
    width: ${size(36)};
    height: ${size(24)};
    margin: ${size(1)};
  `,
  question: css`
    margin: ${size(1)};
    font-size: ${size(3)};
    font-weight: ${fontWeight("bold")};
    ${lineHeight(1.4)}
  `,
  closeButtonTop: css`
    float: right;
    margin: ${size(-5)};
  `,
  closeButton: css`
    pointer-events: all;
    font-size: ${size(7)};
    background-color: ${color("primary", 5)};
    border-radius: ${size(4)};
    height: ${size(6.98)};
  `,
  answerImage: css`
    width: ${size(16)};
    height: ${size(16)};
  `
};

export interface NpcProperties {
  image?: string;
  isMove?: boolean;
  hasCollision?: boolean;
  isRandomMessage: boolean;
  message?: {
    [C in number]: {
      [C in string]: string
    }
  };
  messageImage?: {
    [C in number]: {
      [C in string]: string
    }
  };
  quizId?: string;
  quizCount?: number;
};

export const DAYLY_QUIZ_COUNT: string = "daylyQuizCount";

export class Npc extends withPlayerTrigger(withStories(Actor)) {
  private readonly properties: NpcProperties;
  private characterAnimation: CharacterAnimation;
  // メッセージ関連
  private node: {[C in number]: ReactNode};
  private nodeKey: Array<string>;
  private messageCount: number;
  // 移動範囲
  public areaStartPosition: Vector | null;
  public areaEndPosition: Vector | null;

  private quizzes?: Array<RandomQuiz> = [];
  private currentQuizIndex?: number = 0;
  private quizCount!: number;

  public constructor(configs: FieldEntityConfigs<NpcProperties>) {
    super({
      ...configs,
      width: 96,
      height: 96
    });
    this.properties = configs.properties;
    this.characterAnimation = new CharacterAnimation();

    this.node = {};
    this.nodeKey = Object.keys(this.properties.message!);
    this.messageCount = 0;

    this.areaStartPosition = null;
    this.areaEndPosition = null;

    this.addComponent(new FollowingNodeComponent({anchor: "top"}));
    this.setupCollision();
    this.setupNode();
    this.setupStory();
    this.on("playerTrigger.touchpressed", this.showMessage.bind(this));
    this.on("playerTrigger.touchend", this.hideMessage.bind(this));

    // 今日のクイズ正解数をローカルから取得
    this.loadQuizCount();

  }

  public override onInitialize(engine: Engine): void {
    if (this.properties.image != null) {
      this.characterAnimation.setupAnimations(this.graphics, this.properties.image, this.height);
    }
  }

  public override onPreUpdate(engine: Engine, delta: number): void {
    if (this.properties.image != null) {
      this.characterAnimation.updateAnimation(this.graphics, this.vel);
    }
  }

  private loadQuizCount(): void {
    this.quizCount = 0;
    if (this.properties.quizId !== null) {
      const value = localStorage.getItem(`${DAYLY_QUIZ_COUNT}_${this.properties.quizId}`);
      if (value !== null) {
        const [dateString, count] = value.split("_");
        const dateInt = parseInt(dateString);
        const dayjsDate = dayjs().month() * 100 + dayjs().day();
        if (dateInt === dayjsDate) {
          this.quizCount = parseInt(count);
        }
      }
    }
  }

  private saveQuizCount(): void {
    const dayjsDate = dayjs().month() * 100 + dayjs().day();
    localStorage.setItem(`${DAYLY_QUIZ_COUNT}_${this.properties.quizId}`, `${dayjsDate}_${this.quizCount.toString()}`);
  }

  /**
   * 当たり判定を追加します。
   */
  private setupCollision(): void {
    if (this.properties.hasCollision) {
      const actor = new Actor({collider: Shape.Box(48, 32)});
      actor.body.collisionType = CollisionType.Fixed;
      actor.pos = new Vector(0, 16);
      this.addChild(actor);
    }
  }

  private setupNode(): void {
    this.messageCount = 0;
    this.nodeKey = Object.keys(this.properties.message!);
    this.node = {};
    this.nodeKey.forEach((key) => {
      const imageUrl = this.properties.messageImage?.[+key]?.[global.locale];
      if (imageUrl !== undefined && imageUrl?.length > 0) {
        // 画像あり
        this.node[+key] = (
          <div css={styles.root}>
            <img css={styles.image} src={this.properties.messageImage?.[+key]?.[global.locale]}/>
            <div>{this.properties.message?.[+key]?.[global.locale]}</div>
          </div>
        );
      } else {
        // 画像なし
        this.node[+key] = (
          <div css={styles.root}>
            <div>{this.properties.message?.[+key]?.[global.locale]}</div>
          </div>
        );
      }
    });
  }

  /**
   * ランダム移動処理を追加します。
   */
  private setupStory(): void {
    this.stories.addStory(function *(this: Npc) {
      const followingNode = this.get(FollowingNodeComponent)!;
      const random = new Random();
      while (true) {
        if (this.areaStartPosition === null || this.areaEndPosition === null || followingNode.node !== null) {
          yield* this.stories.storyWait(1000);
          continue;
        }

        const rate = random.integer(0, 7);// 確率は適当。いつか指定があれば修正
        switch (rate) {
        case 0:
          yield* this.storyMoveByHasArea(new Vector(10, 0), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 1:
          yield* this.storyMoveByHasArea(new Vector(-10, 0), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 2:
          yield* this.storyMoveByHasArea(new Vector(0, 10), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        case 3:
          yield* this.storyMoveByHasArea(new Vector(0, -10), 10, this.areaStartPosition, this.areaEndPosition);
          break;
        default:
          yield* this.stories.storyWait(1000);
          break;
        }
      }
    }.bind(this));
  }


  private showMessage(): void {
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    // クイズ出題チェック
    if (this.properties.quizId !== undefined &&
      this.properties.quizCount !== undefined &&
      this.properties.quizCount > this.quizCount) {
      if (folloingNodeComponent.node !== null) {
        return;
      }

      this.askChallenge();
    } else if (this.properties.quizId !== undefined &&
      this.properties.quizCount !== undefined &&
      this.properties.quizCount <= this.quizCount) {
      const trans = createTranslation("npcQuizPane").trans;
      folloingNodeComponent.node = (
        <div css={styles.root}>
          <div css={styles.closeButtonTop}>
            <button css={styles.closeButton} type="button" onClick={() => this.hideMessage()}><FontAwesomeIcon icon={faXmarkCircle}/></button>
          </div>
          <p css={styles.question}>
            {trans("quizEnd")}
          </p>
        </div>
      );
    } else {
      this.talk();
    }

    // プレイヤーの方を向く
    this.turnToPlayer();
  }

  private hideMessage(): void {
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    folloingNodeComponent.node = null;
  }

  private turnToPlayer(): void {
    const player = this.get(FieldEntityComponent)!.player;
    const pos = new Vector(player.pos.x - this.pos.x, player.pos.y - this.pos.y);
    if (Math.abs(pos.x) > Math.abs(pos.y)) {
      pos.y = 0;
    } else {
      pos.x = 0;
    }
    this.characterAnimation.updateAnimation(this.graphics, pos);
    this.vel = vec(0, 0);
  }

  public *storyMoveByHasArea(destPos: Vector, speed: number, areaStartPosition: Vector, areaEndPosition: Vector): StoryGenerator {
    const targetPosition = new Vector(this.pos.x + destPos.x, this.pos.y + destPos.y);
    this.vel = targetPosition.sub(this.pos).normalize().scale(speed);

    while (this.vel.x !== 0 || this.vel.y !== 0) {
      if ((this.vel.x > 0 && this.pos.x > areaEndPosition?.x) ||
      (this.vel.x < 0 && this.pos.x < areaStartPosition?.x) ||
      (this.vel.y > 0 && this.pos.y > areaEndPosition?.y) ||
      (this.vel.y < 0 && this.pos.y < areaStartPosition?.y)
      ) {
        this.vel = vec(0, 0);
        break;
      }

      const delta = yield;

      if (targetPosition.distance(this.pos) <= speed * delta / 1000) {
        this.vel = vec(0, 0);
        this.pos = targetPosition.clone();
        break;
      }
    }
  }

  private talk(): void {
    // メッセージ表示(ランダム表示で分岐)
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    if (this.properties.isRandomMessage) {
      const key = this.nodeKey[Math.floor(Math.random() * this.nodeKey.length)];
      folloingNodeComponent.node = this.node[+key];
    } else {
      const key = this.nodeKey[this.messageCount];
      folloingNodeComponent.node = this.node[+key];
      this.messageCount ++;
      if (this.messageCount >= this.nodeKey.length) {
        this.messageCount = 0;
      }
    }
  }

  private askChallenge(): void {
    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    folloingNodeComponent.node = (
      <div>
        <YesNoPane message="quizChallenge" onPlay={() => this.showQuiz()} onClose={() => this.hideMessage()}/>
      </div>
    );
  }

  public async showQuiz(): Promise<void> {
    // ランダムクイズ生成
    this.quizzes = await createQuizzes(this.properties.quizId!);
    this.currentQuizIndex = this.getNextQuizIndex();
    const quiz = this.quizzes![this.currentQuizIndex];
    const handleClickChoice = (choice: RandomQuiz["choices"][number]): void => {
      createEncounter({
        appId: APP_IDS.qroud,
        quizId: quiz.id,
        playableDeckId: quiz.deckId,
        playTime: 0,
        playAt: dayjs().unix().toString()
      }, []);

      this.setAnswer(quiz, choice.isCorrect, choice.text);
    };

    const folloingNodeComponent = this.get(FollowingNodeComponent)!;
    folloingNodeComponent.node = (
      <div>
        <NpcQuizPane quiz={quiz} onPlay={handleClickChoice} onClose={() => this.hideMessage()} isResult={false} isCorrect={false} selectIndex={-1} correctText=""/>
      </div>
    );
    this.quizCount ++;
    this.saveQuizCount();
  }

  private setAnswer(quiz: RandomQuiz, isCorrect: boolean, answerText: string): void {
    sound.playSe(isCorrect ? SES.correct : SES.incorrect);
    this.stories.addStory("showAnswer", function *(this: Npc) {
      const folloingNodeComponent = this.get(FollowingNodeComponent)!;
      folloingNodeComponent.node = (
        <div css={styles.root}>
          <img css={styles.answerImage} src={isCorrect ? "assets/sprites/common/correct.png" : "assets/sprites/common/incorrect.png"}/>
        </div>
      );

      while (true) {
        yield* this.showResult(quiz, isCorrect, answerText);
      }
    }.bind(this));
  }

  private *showResult(quiz: RandomQuiz, isCorrect: boolean, answerText: string): Generator<unknown, void, number> {
    let time = 0;
    while (true) {
      time += yield;
      if (time > 1000) {
        const folloingNodeComponent = this.get(FollowingNodeComponent)!;
        folloingNodeComponent.node = (
          <div>
            <NpcQuizPane quiz={quiz} onPlay={() => this.showQuiz()} onClose={() => this.hideMessage()} isResult={true} isCorrect={isCorrect} selectIndex={-1} correctText={answerText}/>
          </div>
        );
        this.stories.removeStory("showAnswer");
        break;
      }
    }
  }

  private getNextQuizIndex(): number {
    return Math.floor(Math.random() * this.quizzes!.length);
  }
}