import {writeLog} from "@baton8/quizium-lib-repositories";
import {Actor, CollisionStartEvent, CollisionType, ImageSource} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {StoreKeeperPazzlePlayer} from "./storeKeeperPazzlePlayer";


export interface PazzleResetTriggerProperties {
  color: number;
  pazzleObjectId: number;
};

export class PazzleResetTrigger extends withFieldEntity(Actor) {
  private readonly properties: PazzleResetTriggerProperties;
  private pazzlePlayer?: StoreKeeperPazzlePlayer;

  public constructor(configs: FieldEntityConfigs<PazzleResetTriggerProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));

    const image = new ImageSource("assets/sprites/common/resetbutton.png");
    image.load().then(() => {
      const sprite = image.toSprite();
      sprite.width = 64;
      sprite.height = 32;
      this.graphics.use(sprite);
    });


    writeLog("### PazzleResetTrigger", "constructor");
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other === this.player) {
      this.pazzlePlayer!.resetPosition();

      writeLog("### onCollisionStart", "onCollisionStart");
    } else if (event.other instanceof StoreKeeperPazzlePlayer) {
      this.pazzlePlayer = event.other;
    }
  }
}