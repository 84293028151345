import {Actor, CollisionStartEvent, CollisionType, ImageSource, Sprite} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {PazzlePlayer} from "./pazzlePlayer";

export interface PazzleTriggerProperties {
    /**
     *
     */
    targetLeft: number;
    /**
     *
     */
    targetRight: number;
  };

export class PazzleTrigger extends withFieldEntity(Actor) {
  private readonly properties: PazzleTriggerProperties;
  private sprite?: Sprite;
  private pazzleController?: PazzlePlayer;

  public constructor(configs: FieldEntityConfigs<PazzleTriggerProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));

    const pass = "assets/sprites/common/change.png";
    const image = new ImageSource(pass);
    image.load().then(() => {
      this.sprite = image.toSprite();
      this.sprite.width = 32;
      this.sprite.height = 32;
      this.graphics.use(this.sprite);
    });

    // writeLog("###", "PazzleTrigger");
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other === this.player && this.pazzleController !== null) {
      const left = this.properties.targetLeft;
      const right = this.properties.targetRight;
      this.pazzleController!.changeTergetValue(left, right);
    } else if (event.other instanceof PazzlePlayer) {
      this.pazzleController = event.other as PazzlePlayer;
    }
  }
}