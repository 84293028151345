import {Actor, CollisionType, Color, Font, Text} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {ColorPazzlePlayer} from "./colorPazzlePlayer";

export interface ColorPazzleResultProperties {
};

export class PazzleResultView extends withFieldEntity(Actor) {
  private readonly properties: ColorPazzleResultProperties;
  private pazzleController?: ColorPazzlePlayer;
  private successView: Actor;

  public constructor(configs: FieldEntityConfigs<ColorPazzleResultProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;

    this.successView = new Actor();
    this.addChild(this.successView);
    const text = new Text({
      text: "成功！",
      font: new Font({
        bold: true,
        color: Color.Red,
        size: 30
      })
    });
    this.successView.graphics.add("text", text);
  }

  public Show(): void {
    this.successView.graphics.show("text");
  }
}