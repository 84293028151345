import {Actor, CollisionType, Sprite} from "excalibur";

export class SlidePazzleImageView extends Actor {
  public index?: number;

  public constructor() {
    super({width: 48, height: 48});
    this.body.collisionType = CollisionType.Passive;
  }

  public setImage(index: number, sprite: Sprite | null): void {
    this.index = index;
    if (sprite !== null) {
      sprite.width = 48;
      sprite.height = 48;
      this.graphics.add("image", sprite);
      this.graphics.show("image");
    }
  }
}