import {Actor, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {ColorPazzleTrigger} from "./colorPazzleTrigger";
import {PazzleResultView} from "./pazzleResultView";

export interface ColorPazzleControllerProperties {
  row: number; // 行
  column: number; // 列
  tileCount: number; // タイル数
  tileSpace: number; // タイル間の間隔
  image0: string;
  image1: string;
};

export class ColorPazzlePlayer extends withFieldEntity(Actor) {
  private readonly properties: ColorPazzleControllerProperties;
  private objectsSet: Set<ColorPazzleTrigger>;
  private pazzleResultView?: PazzleResultView;
  public isCorrect: boolean;

  public constructor(configs: FieldEntityConfigs<ColorPazzleControllerProperties>) {
    super({
      ...configs
    });
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));

    this.objectsSet = new Set();
    this.isCorrect = false;

    // const actor = new Actor();
    // this.addChild(actor);
    // const text = new Text({
    //   text: "成功！",
    //   font: new Font({
    //     bold: true,
    //     color: Color.Red,
    //     size: 30
    //   })
    // });
    // actor.graphics.add("text", text);
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof ColorPazzleTrigger) {
      this.objectsSet?.add(event.other);

      if (this.objectsSet.size === this.properties.tileCount) {
        let count = 0;
        this.objectsSet.forEach((x) => {
          x.image0 = this.properties.image0;
          x.image1 = this.properties.image1;
          x.createImage();
          x.pazzleObjectId = count;
          count ++;
        });

        this.objectsSet.forEach((a) => {
          this.objectsSet.forEach((b) => {
            if (a.pazzleObjectId !== b.pazzleObjectId) {
              const xx = a.pos.x - b.pos.x;
              const yy = a.pos.y - b.pos.y;
              const spaceLength = this.properties.tileSpace + 4;
              if (xx * xx + yy * yy <= spaceLength * spaceLength) {
                a.tree.add(b);
              }
            }
          });
        });
      }
    } else if (event.other instanceof PazzleResultView) {
      this.pazzleResultView = event.other;
    }
  }

  public checkResult(): void {
    const colornumber = 1;// [...this.objectsSet][0].colorNumber;

    let result = true;
    this.objectsSet.forEach((x) => {
      if (x.colorNumber !== colornumber) {
        result = false;
      }
    });

    this.isCorrect = result;
    if (this.isCorrect) {
      this.pazzleResultView?.Show();
    }
  }
}