import {Actor, CollisionStartEvent, CollisionType, ImageSource, Sprite} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {ColorPazzlePlayer} from "./colorPazzlePlayer";

export interface ColorPazzleTriggerProperties {
  color: number;
  pazzleObjectId: number;
};

export class ColorPazzleTrigger extends withFieldEntity(Actor) {
  private readonly properties: ColorPazzleTriggerProperties;
  private sprite?: Sprite;
  private pazzleController?: ColorPazzlePlayer;
  public pazzleObjectId?: number;
  public colorNumber: number;
  public tree: Set<ColorPazzleTrigger>;

  public image0?: string;
  public image1?: string;


  public constructor(configs: FieldEntityConfigs<ColorPazzleTriggerProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));

    this.colorNumber = this.properties.color;
    this.tree = new Set<ColorPazzleTrigger>();

    this.pazzleObjectId = this.properties.pazzleObjectId;
  }

  public createImage(): void {
    const image = new ImageSource(this.image0!);
    image.load().then(() => {
      this.sprite = image.toSprite();
      this.sprite.width = 44;
      this.sprite.height = 44;
      this.graphics.add("red", this.sprite);
      if (this.properties.color === 0) {
        this.graphics.show("red");
      }
    });

    const image0 = new ImageSource(this.image1!);
    image0.load().then(() => {
      this.sprite = image0.toSprite();
      this.sprite.width = 44;
      this.sprite.height = 44;
      this.graphics.add("blue", this.sprite);
      if (this.properties.color === 1) {
        this.graphics.show("blue");
      }
    });
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other === this.player && this.pazzleController !== null) {
      if (!this.pazzleController?.isCorrect) {
        this.changeColor();
        this.tree.forEach((value) => {
          value.changeColor();
        });

        this.pazzleController?.checkResult();
      }
    } else if (event.other instanceof ColorPazzlePlayer) {
      this.pazzleController = event.other as ColorPazzlePlayer;
    }
  }

  public changeColor(): void {
    if (this.colorNumber === 0) {
      this.graphics.hide("red");
      this.graphics.show("blue");
      this.colorNumber = 1;
    } else {
      this.graphics.hide("blue");
      this.graphics.show("red");
      this.colorNumber = 0;
    }
  }
}